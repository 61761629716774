import CONFIG from "@/config";
import api from "@/api/axiosFactory";

let list;
let details;

export default {
    async list() {
        return list ||= new Promise(r => r(api({url: CONFIG.api.facility.details})))
            .then(response => response.data)
            .then(data => {
                const getUnitTitle = u => {
                    switch (u.type) {
                        case "locker":
                            return "1 м&sup3; (высота 1м)";
                        case "entresol":
                            return `Антресоль ${u.surface} м&sup2;`;
                        case "low":
                            return `Низкий ${u.surface} м&sup2;`;
                        default:
                            return `${u.surface} м&sup2;`;
                    }
                };

                data.forEach(facility => facility.unit_types.forEach(u => u.name = getUnitTitle(u)));
                return details = data;
            });
    },
    async free(facilityCode) {
        return (await api({url: `${CONFIG.api.facility.facility}/${facilityCode}/types/free`})).data;
    },
    get(facilityCode) {
        return (details||[]).find(facility => facility.facility_code === facilityCode);
    }
};
